import { useEffect } from 'react';
import { EventHelper } from '../utils';

export const useFocusPanel = (isOpen: boolean) => {
  useEffect(() => {
    EventHelper.focusActivePanel();
    return () => {
      EventHelper.focusActivePanel();
    };
  }, [isOpen]);
};
