export const KeyDownCode = {
  Space: 'Space',
  Enter: 'Enter',
  NumpadEnter: 'NumpadEnter',
  Semicolon: ';',
  Backspace: 'Backspace',
  ArrowDown: 'ArrowDown',
  ArrowUp: 'ArrowUp',
  Comma: ',',
  Escape: 'Escape',
  Save: 's',
};

export enum KeyDownWithPanel {
  Escape = 0,
  Save = 1,
}
