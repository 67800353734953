import { KeyDownCode } from '../constants/key-down-code.constant';

 const buttonSaveClassName = 'onix-save-button';
 const buttonCloseClassName = 'onix-close-button';
type GenericEvent = {
  preventDefault(): void;
  stopPropagation(): void;
};

const eventPreventDefaultAndStopPropagation = <E extends GenericEvent>(event: E) => {
  event.preventDefault();
  event.stopPropagation();
};

const checkHasOverlayForLastLayer = () => {
  const layer = getLastLayer();
  if (layer) {
    const overlay = layer.querySelector('.ms-Overlay');
    return overlay ? true : false;
  }
};

const checkActionEvent = (event: any) => {
  const targetId = event.target.id;
  const panelLastId = getLastPanelId();
  if (!panelLastId) return false;
  if (panelLastId === targetId) {
    return false;
  }

  if (!targetId && event.target.classList.contains('close-button')) {
    const parent = event.target.parentNode.parentNode.parentNode.parentNode.parentNode;
    if (parent.id === panelLastId) return false;
  }

  const panel = document.getElementById(panelLastId);
  if (panel && targetId) {
    const element = panel?.querySelector(`[id='${targetId}']`);
    if (element) {
      return false;
    }
  }

  return true;
};

const onActionKeyDown = (event: any) => {
  const loadingPanel = null;
  const wasAnyKeyEscapePressed = event.key == KeyDownCode.Escape;
  if (wasAnyKeyEscapePressed) {
    onActionKeyDownForPanel(event, buttonCloseClassName, loadingPanel, 0);
    return;
  }

  const wasAnyKeySavePressed = event.key.toLowerCase() === KeyDownCode.Save && event.ctrlKey;
  if (wasAnyKeySavePressed) {
    onActionKeyDownForPanel(event, buttonSaveClassName, loadingPanel, 300);
    return;
  }
};

const onActionKeyDownForPanel = (event: any, className: string, loadingPanel: any, time = 500) => {
  const button = getButtonByClassName(className);
  if (button) {
    eventPreventDefaultAndStopPropagation(event);
    if (loadingPanel) return;
    // if (checkActionEvent(event)) return;
    autoClickButton(button, time);
  }
};

const focusActivePanel = () => {
  activePanel('ms-Panel-contentInner');
};

const focusInputFirstLoad = () => {
  const layer = getLastLayer();
  if (layer) {
    const panel = layer.querySelector(`.ms-Panel-contentInner`);
    const panelInputAutoFocus = panel?.querySelector('.panel-input-auto-focus') as HTMLInputElement;
    if (panelInputAutoFocus) {
      const input = panelInputAutoFocus.querySelector('input');
      if (input) {
        input.focus();
        return;
      }
    }
  }
};

const autoClickButton = (button: any, time = 500) => {
  if (button) {
    button.focus();
    setTimeout(() => {
      button.click();
    }, time);
  }
};

const getButtonByClassName = (className: string) => {
  const layers = document.querySelectorAll(`.ms-Layer`);
  if (layers) {
    const layer = layers[layers.length - 1] as HTMLElement;
    if (layer) {
      const button = layer.querySelector(`.${className}`) as HTMLButtonElement;
      if (className === buttonSaveClassName && button && button.disabled) return null;
      if (!button && className === buttonCloseClassName) {
        const closeSelectors = ['.ms-Panel-closeButton', '.close-button'];
        const btnClose = layer.querySelector(closeSelectors.join(', ')) as HTMLButtonElement;
        return btnClose;
      }
      return button;
    }
  }
  return null;
};

const getLastPanelId = () => {
  const layer = getLastLayer();
  if (layer) {
    const panel = layer.querySelector(`.ms-Panel`);
    if (panel) {
      return panel.id;
    }
  }
  return null;
};

const getLastLayer = () => {
  const layers = document.querySelectorAll(`.ms-Layer`);
  if (layers) {
    const layer = layers[layers.length - 1] as HTMLElement;
    return layer;
  }
  return null;
};

const activePanel = (className: string = 'pwsb-panel-header') => {
  setTimeout(() => {
    const layer = getLastLayer();
    if (layer) {
      const panel = layer.querySelector(`.${className}`);
      if (!panel) return;
      const panelAddTextFieldSearch = panel?.querySelector('.tfm-add-suggestion-textfield');
      if (panelAddTextFieldSearch) {
        const input = panelAddTextFieldSearch.querySelector('input');
        if (input) {
          input.focus();
          return;
        }
      }

      const inputSearch = panel?.querySelector('.ms-SearchBox-field') as HTMLInputElement;
      if (inputSearch) {
        inputSearch.focus();
        return;
      }

      const button = panel?.querySelector('button');
      if (button) {
        button.focus();
        return;
      }

      const input = panel?.querySelector('input');
      if (input) {
        input.focus();
        return;
      }
    }
  }, 300);
};

const focusErrorField = (className: string = ''): void => {
  setTimeout(() => {
    const form = className ? document.querySelector(`.${className}`) ?? document : document;
    const errorSelectors = [
      '[class*="errorMessage"]',
      '[class*="tfs-error-message"]',
      '[class*="error-messages"]',
      'div.onix-tag-picker.required',
      '.ms-TextField-errorMessage',
    ];
    const firstErrorField = form.querySelector(errorSelectors.join(', '));
    if (firstErrorField) {
      const targetElement = findTargetElement(firstErrorField);
      if (targetElement) {
        focusAndScroll(targetElement);
      }
    }
  }, 200);
};

const findTargetElement = (element: Element): HTMLElement | null => {
  const inputField = element.querySelector('input, textarea');
  if (inputField) return inputField as HTMLElement;

  const containerSelectors = ['.ms-TextField', '.ms-Dropdown-container', '.tfs'];
  for (const selector of containerSelectors) {
    const container = element.closest(selector);
    if (container) {
      const inputOrDropdown = container.querySelector('input, textarea, .ms-Dropdown');
      if (inputOrDropdown) return inputOrDropdown as HTMLElement;
    }
  }

  const parent = element.parentElement;
  if (parent) {
    const inputTagField = parent.querySelector('input');
    if (inputTagField) return inputTagField as HTMLElement;
  }

  return null;
};

const focusAndScroll = (element: HTMLElement): void => {
  element.focus();
  element.scrollIntoView({ behavior: 'smooth', block: 'center' });
};


export  const EventHelper  = {
  checkHasOverlayForLastLayer,
  eventPreventDefaultAndStopPropagation,
  focusActivePanel,
  focusErrorField,
  focusInputFirstLoad,
  onActionKeyDown,
  buttonCloseClassName,
  buttonSaveClassName
};

