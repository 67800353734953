import { css, IconButton, IPanelHeaderRenderer, IPanelProps, IRenderFunction, Panel, PanelType, Separator } from '@fluentui/react';
import { useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useDismissPanel } from '../../hooks/useDismissPanel';
import { useFocusPanel } from '../../hooks/useFocusPanel';
import { EventHelper } from '../../utils';
import Shimmer from '../Shimmer/Shimmer';
import './index.scss';

const DEFAULT_LOADING_SKELETONS = 5;

/**
 * Properties for `PanelWithScrollableBody` component.
 */
export interface PanelWithScrollableBodyProps {
  /**
   * Additional class name for panel.
   */
  className?: string;

  /**
   * Set panel is opened.
   */
  isOpen?: boolean;

  /**
   * On dimiss callback.
   */
  onDismiss?: (ev?: any) => void;

  renderHeader?: () => JSX.Element;

  /**
   * Title for the panel.
   */
  title?: string;

  /**
   * Small explanation text for the title.
   */
  content?: string;

  /**
   * Panel width, default value is 968px.
   */
  width?: string;

  /**
   * If isLoading is true, display the loading skeleton.
   */
  isLoading?: boolean;

  /**
   * Configure the abount of loading skeletons, default is 5.
   */
  isLoadingSkeletons?: number;

  /**
   * Render additional things for the header. The header is wrapped by CSS `.pwsb-panel-header` class.
   *
   * If you want to render more, below the `title` and the `content`,
   * e.g.: search textfield, an input textfield + add button combo.
   */
  renderAdditionalHeader?: IRenderFunction<IPanelProps>;

  /**
   * Render body. Wrapped by CSS `.pwsb-panel-body`
   */
  renderBody?: IRenderFunction<IPanelProps>;

  /**
   * Render footer. Wrapped by CSS `.pwsb-panel-footer > .pwsb-panel-footer-inner` class.
   */
  renderFooter?: IRenderFunction<IPanelProps>;

  /**
   * Custom fluent panel props.
   */
  panelProps?: IPanelProps;
  /**
   * show Separator or not
   */
  hideSeparator?: boolean;

  focusTrapZonePropsDisabled?: boolean;

  onRefresh?: () => void;

  isLightDismiss?: boolean;

  tabIndex?: number;
}

export interface IPanelResult {
  action?: string;
  data?: any;
  isChanged?: boolean;
}

/**
 * Simple wrapper for Fluent Panel component. Allow scrollable body with static header and footer.
 */
export const PanelWithScrollableBody = (props: PanelWithScrollableBodyProps) => {
  const width = props.width || '968px';
  const title = props.title;
  const focusTrapZonePropsDisabled = props.focusTrapZonePropsDisabled;
  const className = `${props.className} pwsb-panel`;
  const isLightDismiss = props.isLightDismiss ?? true;
  const tabIndex = props.tabIndex ?? 0;
  //const isLoading = useSelector(appIsLoadingSelector);
  const isLoading = false;

  const onDismiss = useDismissPanel((ev: any) => {
    if (isLoading) {
      return;
    }
    props.onDismiss?.(ev);
  });

  const renderPanelHeader: IPanelHeaderRenderer = useCallback(
    (panelProps, defaultRenderer) => {
      return (
        <div className="pwsb-panel-header">
          {props.renderHeader ? props.renderHeader() : <span className="title">{title ?? <>&nbsp;</>}</span>}
          {props.onRefresh ? (
            <IconButton
              onClick={(ev: any) => props.onRefresh!()}
              tabIndex={-1}
              className="refresh-button"
              iconProps={{ iconName: 'Refresh' }}
            />
          ) : (
            <></>
          )}
          <IconButton
            onClick={(ev: any) => panelProps?.onDismiss?.(ev)}
            tabIndex={-1}
            className={`close-button ${EventHelper.buttonCloseClassName}`}
            iconProps={{ iconName: 'Cancel' }}
          />
          {props.content && <span className="content">{props.content}</span>}
          {props.renderAdditionalHeader?.(panelProps, defaultRenderer)}
          {!props.hideSeparator ? (
            <Separator styles={{ root: { height: '5px', marginBottom: '10px', marginTop: '-3px' } }} />
          ) : (
            <div style={{ height: 2 }}></div>
          )}
        </div>
      );
    },
    [title, props]
  );

  const renderPanelBody: IRenderFunction<IPanelProps> = useCallback(
    (panelProps, defaultRenderer) => {
      return (
        <div className={css('pwsb-panel-body', props.isLoading && 'is-loading')}>
          {props.isLoading ? (
            <Shimmer equalLine lines={props.isLoadingSkeletons ?? DEFAULT_LOADING_SKELETONS} className="" />
          ) : (
            props.renderBody?.(panelProps, defaultRenderer)
          )}
        </div>
      );
    },
    [props.renderBody, props.isLoading, props.isLoadingSkeletons]
  );

  const renderPanelFooter: IRenderFunction<IPanelProps> = useCallback(
    (panelProps, defaultRenderer) => {
      // Class .pwsb-panel-footer is a container, has max width of the panel and position stick to the bottom with WHITE BACKGROUND.
      // Class .pwsb-panel-footer-inner provides padding.
      if (props.renderFooter) {
        return (
          <div className="pwsb-panel-footer">
            <div className="pwsb-panel-footer-inner">{props.renderFooter(panelProps, defaultRenderer)}</div>
          </div>
        );
      }
      return null;
    },
    [props.renderFooter]
  );
  const panelId = 'panel' + uuidv4();
  useFocusPanel(props.isOpen ?? false);

  return (
    <Panel
      {...props.panelProps}
      id={props?.panelProps?.id ?? panelId}
      tabIndex={tabIndex}
      className={className}
      isOpen={props.isOpen}
      onRenderHeader={renderPanelHeader}
      onRenderBody={renderPanelBody}
      onRenderFooter={renderPanelFooter}
      onDismiss={onDismiss}
      customWidth={width}
      type={props.panelProps?.type ?? PanelType.custom}
      hasCloseButton={false}
      isFooterAtBottom={true}
      focusTrapZoneProps={{ disabled: focusTrapZonePropsDisabled ?? true }}
      layerProps={{ eventBubblingEnabled: true }}
      isLightDismiss={isLightDismiss}
      onKeyDown={(e) => {
        EventHelper.onActionKeyDown(e);
      }}
      // onKeyDownCapture={(ev) => {
      //   SingleSelectionIgnoreScrollOutside(ev);
      // }}
    />
  );
};
