import { useCallback, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { useDebouncedEffect } from './useDebouncedEffect';

export const useInfinityScroll = (): [string | undefined, (ev: any) => void, (value: string | undefined) => void] => {
  const [internalFetchMoreId, setInternalFetchMoreId] = useState<string | undefined>(undefined);
  const [externalFetchMoreId, setExternalFetchMoreId] = useState<string | undefined>(undefined);
  const scrollInfo = useRef<{ scrollLeft: number; scrollTop: number }>({ scrollLeft: 0, scrollTop: 0 });

  useDebouncedEffect(
    () => {
      setExternalFetchMoreId(internalFetchMoreId);
    },
    [internalFetchMoreId],
    500
  );

  const onScroll = useCallback((ev?: any) => {
    if (ev) {
      const newScrollInfo = {
        scrollLeft: ev?.target?.scrollLeft,
        scrollTop: ev?.target?.scrollTop,
      };

      if (newScrollInfo.scrollTop !== scrollInfo.current.scrollTop) {
        const height = ev?.target?.scrollHeight - ev?.target?.scrollTop - ev?.target?.clientHeight;
        const isValid = height < 40;
        if (isValid) {
          const id = uuid();
          setInternalFetchMoreId(id);
        }
      }
      scrollInfo.current.scrollLeft = newScrollInfo.scrollLeft;
      scrollInfo.current.scrollTop = newScrollInfo.scrollTop;
    }
  }, []);

  return [externalFetchMoreId, onScroll, setExternalFetchMoreId];
};
