import { DirectionalHint, ITooltipHostProps, TooltipHost } from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import { v4 as uuid } from 'uuid';

export const OnixTooltip = (props: ITooltipHostProps) => {
  const id = useId(uuid());
  const { children, content, directionalHint, calloutProps } = props;

  return (
    <TooltipHost id={id} content={content} directionalHint={directionalHint ?? DirectionalHint.topLeftEdge} calloutProps={calloutProps}>
      {children}
    </TooltipHost>
  );
};
